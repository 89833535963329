import React from "react";
import Seo from "../../components/seo";
import Industry from "../../layouts/Industries/Industry";
import {graphql, useStaticQuery} from 'gatsby';
import Layout from "../../components/layout";
import {Glasses} from '../../components/svgs/industries';

const EyewearOpticsIndustryPageFr = () => {
  const data = useStaticQuery(graphql`
    query EyewearOpticsIndustryDatasFr {
      allContentfulSecteurs(filter: {contentful_id: {eq: "PWF1ScawoaaxnKIgjOtuc"}}) {
        edges {
          node {
            name
            permalink
            contentful_id
            advantages {
              title
              description
              icon
              visual {
                gatsbyImageData(layout: CONSTRAINED)
              }
            }
            installations {
              gatsbyImageData(layout: CONSTRAINED)
            }
            clients {
              gatsbyImageData(layout: CONSTRAINED)
            }
          }
        }
      }
    }
  `)

  return (
    <Layout 
    slider={false} 
    switchLink={data.allContentfulSecteurs.edges[1].node.permalink} 
    lang="fr-CA"
    title={data.allContentfulSecteurs.edges[0].node.name} 
    subTitle="L'affichage dynamique en"
    icon={<Glasses/>}>
      <Seo 
      title="Écrans publicitaire intérieur pour la promotion en lunetterie"
      description="Technologies d'affichage numérique dynamique et interactif, bornes interactives, gestion de contenu, marketing créatif. C'est ça ATTRACTIF.ca."
      />
      <Industry datas={data.allContentfulSecteurs.edges[0].node}/>
    </Layout>
  )
}

export default EyewearOpticsIndustryPageFr
